.flight_name{color:white;font-weight: bold;text-align: center;padding:0.1rem;border-radius: 0px;}
/* .flight-id-JR{} */
.flight_name-JR{background-color:rgb(25, 84, 133) }
.flight_name-MT{background-color:rgb(124, 123, 123) }
.flight_name-MVD{background-color:orange}
.flight_name-TRA{background-color:rgb(20, 105, 65)}
.flight_name-CNL{background-color:rgb(20, 102, 105)}
.logbook .list-item{border-radius: 0px;overflow: hidden;}
.logbook .list-item>div:nth-child(1), .logbook .list-item>div:nth-child(3){border-bottom: 1px solid rgb(37, 40, 46) }
.logbook .list-item>div:nth-child(2){border-bottom: 1px solid rgb(201, 201, 201) }
.logbook .fuel.list-item>div:nth-child(1), .logbook .fuel.list-item>div:nth-child(3){border-bottom: 1px solid rgb(66, 145, 95) }
.logbook .list-item>div:nth-child(3){min-width: 80px }
.tab{border-radius: 5px;margin-top: 0.4rem;}
