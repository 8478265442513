/* BODY */
html{height:100%}
body {
  margin: 0;  padding: 0;  height:100%; color:rgb(255, 255, 255);font-size:10pt;overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body:before {
  content: "";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  /* background: var(--day) no-repeat; */
  background-color: var(--bg-main);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
}
.page { padding-top: 45px !important;padding-bottom: 55px !important;}
.extra-margin-top{margin-top: 35px}
:root{
  --bg-main: #9e9e9e;
  --day:linear-gradient(to bottom, rgb(25, 84, 133), rgb(189, 47, 47));
  --day2:linear-gradient(to bottom, rgb(25, 84, 133), rgb(214, 214, 214));
  --day2:linear-gradient(to bottom, rgb(4, 22, 34), rgb(25, 84, 133));
  --day2-body-bg:rgb(214, 214, 214);
  --day-body-bg:rgb(189, 47, 47);
  --day2-body-bg:rgb(25, 84, 133);
  --bg-pink:rgb(189, 47, 47);
  --bg-blue:rgb(20, 40, 59);


}
/* GENERAL  */
.btn:hover{color:white}
.no-break{white-space: nowrap;}
.close:focus{outline: none}
.modal{color:rgb(65, 65, 65)}
.no-overflow{overflow: hidden;position:fixed}
.page-margin-top{margin-top:45px}
@media(min-width:577px){
  .show-xs{display:none !important}
}
@media(max-width:768px){
  .page-margin-top-sm{margin-top:85px}
}
@media(max-width:576px){
.hidden-xs{display:none !important}
.page-margin-top-xs{margin-top:0}
}
input[type=checkbox]{zoom: 1.5 !important;}
input[type='text'],input[type='number'],select,textarea {  font-size: 16px;}
input.list-input{background-color: transparent;border:none;color:rgb(53, 53, 53);font-size: 10pt;outline: none}
.loading{display:none;cursor:pointer;position: fixed;left:0;top:0;font-size:12pt;color:rgb(170, 170, 170);background-color:rgba(0, 0, 0, 0.5);height:100%;width:100%;z-index:20;}
.loading img{position:absolute;top:50%;left:50%;transform: translate(-50%,-50%);display: block;width:100px;height: 100px}
.loading-show{display:block !important}
.pointer{cursor:pointer}
.v-align{position: absolute;top:50%;left:50%;transform: translate(-50%, -50%)}
.fade-in{animation: fade_in 0.6s 1s forwards;opacity: 0}
.date-combo{white-space: nowrap;}
@media(max-width:576px){
.date-combo select{height:35px}
}

.link:hover{color: orange;cursor: pointer;}
@keyframes fade_in{  from{opacity: 0} to{opacity: 1}}
/* CHART */
.chart{width:100%;height:300px;color:white;font-weight:bold;color:white !important}
.chart-container{padding:5px;background-color: rgba(255, 255, 255, 0.1);border-radius: 5px}
.canvasjs-chart-credit{display: none}

/* FILTER */
.filter{position: fixed;width:100%;left:0;top:40px;background-color: #d4d4d4;padding:0.4rem;z-index:200}
.div-table{margin-top:45px}
/* LOGIN */
.login .form-control, .login .btn{position: relative;}
.login .user{left:-100%;transform: translateX(-200%)}
.login .pass, .login .div-btn{left:100%;transform: translateX(200%)}
.come-100{animation: a_come_100 0.6s forwards;display: block}
@keyframes a_come_100{  from{transform:translateX(200%);left:100%;}  to{transform:translateX(0);left:0;}}
.come-reverse-100{animation: a_come_100_reverse 0.6s forwards;display: block}
@keyframes a_come_100_reverse{  from{transform:translateX(-200%);left:-100%;}  to{transform:translateX(0);left:0;}}

/* FLIGHT */
.flight-labels div{display: inline-block;padding-right:6px}
.flight-trash{position :absolute;bottom: 2px; left : 50%; transform : translateX(-50%);opacity: 0.5;}
.flight-id{font-size: 12pt;white-space: nowrap;font-weight: bold;padding:0;
  background:linear-gradient(to bottom, rgb(16, 58, 92), rgb(25, 84, 133));background-color:rgb(25, 84, 133);color:white;min-height: 60px;} 
.flight-id span{position: absolute;top:50%;left:50%;transform: translate(-50%, -50%);display: inline-block;width:100%} 
@media(max-width:576px){.flight-id{font-size: 10pt;}}
/* .flight-id-JR{} */
.flight-id-B{background:linear-gradient(to bottom, rgb(16, 58, 92), rgb(25, 84, 133));background-color:rgb(25, 84, 133) }
.flight-id-C{background:linear-gradient(to bottom, rgb(163, 163, 163), rgb(163, 163, 163));background-color:rgb(163, 163, 163) }
.flight-id-S, .flight-id-ASU{background:linear-gradient(to bottom, rgb(255, 140, 0), orange);background-color:orange}
.flight-id-M{background:linear-gradient(to bottom, rgb(20, 105, 65), rgb(26, 133, 83));background-color:rgb(20, 105, 65)}
.flight-id-BC{background:linear-gradient(to bottom, rgb(20, 102, 105), rgb(17, 91, 94));;background-color:rgb(20, 102, 105)}

.panel{cursor:pointer;position: absolute;left:0;top:0;font-size:12pt;color:rgb(170, 170, 170);background-color:rgba(0, 0, 0, 0.8);height:100%;width:100%;z-index:10;display:none;transform: translateY(-100%)}
.panel i{font-size: 16pt}
.panel .col:hover{color:orange}
.panel-come{animation: a_panel_come 0.6s forwards}
.panel-go{animation: a_panel_go 0.6s forwards}
.tools-right{position: absolute;right:0;background-color:var(--bg-blue);color:white;height:100%;padding-top: 7px;z-index:10}
.tools-right div:hover{color:orange;cursor: pointer;}
@keyframes a_panel_come{
  from{transform:translateY(-100%)}
  to{transform:translateY(0)}
}
@keyframes a_panel_go{
  from{transform:translateY(0)}
  to{transform:translateY(-100%)}
}
.big-icon{font-size: 12pt}
@media(max-width:576px){.big-icon{font-size: 15pt}}
/* FLIGHT IMAGE */
.flight-image{width:100%}
/* FLIGHT LEG */
.col-date {  -ms-flex: 0 0 50%;      flex: 0 0 50%;  max-width: 50%;}
@media(max-width:576px){.col-date {  -ms-flex: 0 0 100%;      flex: 0 0 100%;  max-width: 100%;}}
/* EXPENSE   */
.expense-mini{width:50px;height:50px;border:0px solid rgb(180, 180, 180);box-shadow: 2px 2px 4px rgb(41, 41, 41);cursor: pointer}
.expense-medium{box-shadow: 2px 2px 4px rgb(20, 20, 20);}
/* EXPENSE VARIABLE LIST */
@media(max-width:576px){.expense-variable-list{margin-top:150px}}

.expense-variable-list .image-modal{position: fixed;left:50%;transform: translateX(-50%);top:10%;z-index: 1000}
.expense-variable-list .image-modal img{max-height: calc(100vh - 150px);}
.expense-variable-list .image-modal-bg {position: fixed;top:0;left:0;width: 100vw;height: 100vh;background-color: rgba(0, 0, 0, 0.5)}
.expense-variable-list table input{max-width:70px}
.expense-variable-list .changed, .expense-variable-list .changed input {color:white;background-color: rgb(41, 85, 151)}

/* DASHBOARD */
.dashboard-panel>div>div{border-radius: 5px;margin-bottom: 5px;height: 100px;padding: 4px;opacity: 1;box-shadow:2px 2px 4px rgb(54, 54, 54) }
.dashboard-panel div.col-md-2 {  -ms-flex: 0 0 15%;flex: 0 0 15%; max-width: 15%;}
.dashboard-panel>div{padding:5px}
@media(min-width:577px){.dashboard-panel .title{font-size: 12pt;background-color: inherit;}}
@media(max-width:576px){.dashboard-panel .title{font-size: 10pt;background-color: inherit;}}
.dashboard-panel .country-list{height:69px;overflow-y: auto}
.dashboard-panel .country-list::-webkit-scrollbar{border-radius: 5px;width: 12px; }
.dashboard-panel .country-list::-webkit-scrollbar-track{background: rgb(212, 117, 0);border-radius: 5px}
.dashboard-panel .country-list::-webkit-scrollbar-thumb{background: rgb(233, 151, 0);border-radius: 5px}
.dashboard-filter select{color:rgb(100, 100, 100);font-size: 11pt}
.dashboard h6{color:rgb(58, 58, 58)}
.bg-dashboard{background-color: rgb(240, 240, 240)}
.myCanvas{border: 0px solid gray;box-shadow:2px 2px 4px rgb(170, 170, 170);border-radius: 5px;background-color: white;padding-top: 10px;padding-right: 5px}
.bg-panel-icon{position: absolute;right:25px;font-size: 50pt;top:0;opacity: 0.2;}
/* FONTS */
.font-xl{font-size: 12pt}
.font-lg{font-size: 11pt}
.font-md{font-size: 10pt}
.font-sm{font-size: 9pt}
.font-xs{font-size: 8pt}
.btn-back{cursor: pointer;}
@media(min-width:576px){.btn-back:hover{color:orange} }
.rounded{border-radius: 10px;overflow: hidden;}
/* TABLE */

table{margin-bottom: 0 !important;color: rgb(63, 63, 63)} 
.table-hover tbody tr:hover {
  background-color: rgb(253, 198, 97);cursor: pointer
}
.td-min{width:20%;text-align: center}
.div-table{border-radius: 0px;overflow: hidden;}
/* table th{background-color: var(--bg-main);color:white;border:1px solid var(--bg-main) !important} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* BGs */
 button:focus{outline:none}
.btn-top{border-radius: 5px;background-color: rgb(202, 202, 202);font-size: 11pt;cursor: pointer;color:rgb(65, 68, 77)};
.btn-100{width:100px}
.btn-80{width:80px}
.btn-main{width:100px;background-color: rgb(68, 167, 107);color:white;}
.bg-blue{background-color: var(--bg-blue) ;color:white;}
.bg-pink{background-color: var(--bg-pink)}
.bg-purple{background-color: rgb(51, 90, 134)}
.bg-gray{background-color: rgb(161, 161, 161)}
.bg-warning-dark{background-color: rgb(211, 127, 0)}
.text-warning-dark{color: rgb(211, 127, 0)}
.text-orange{color:orange}
.text-green{color: rgb(55, 128, 84);}
.bg-red{background-color: rgb(185, 66, 66)}
.text-red{color: rgb(185, 66, 66)}
.bg-success-dark{background-color: rgb(68, 167, 107);color: white}
.text-success-dark{color: rgb(30, 117, 65) !important;}
.bg-cream{background-color: #fdfdfe;color:var(--bg-blue);}
.bg-cream-dark{background-color: #ececf6;color:var(--bg-blue);}
.bg-list{background-color: rgb(43, 58, 75);color:white;padding: 0.4rem;border-bottom:1px solid rgb(50, 59, 95)}
.bg-list-alternate{background-color: rgb(43, 58, 75);color:white;padding: 0.4rem;border-bottom:1px solid rgb(71, 71, 71)}
.bg-list-alternate-2{background-color: rgb(218, 218, 218);color:rgb(15, 15, 15);padding: 0.4rem;border-bottom:1px solid rgb(180, 180, 180)}
.bg-list-alternate-2 i{color:rgb(78, 78, 78);}
.bg-sky{ background-image:linear-gradient(to bottom, rgb(25, 84, 133), white);}
.select-button{width:calc(100% - 110px);display: inline-block;margin-right: 0.5rem}
.btn-right{background-color: #4285F4;color:white;border-radius: 5px;border:1px solid #4285F4}
.btn-right:focus{outline: 0;}
.btn-right:hover{background-color:  rgb(86, 145, 241);border:1px solid rgb(86, 145, 241);cursor:pointer}
.block-inline{display: inline-block}
.hidden{display: none}
.block{display: block}
.text-orange{color:orange}
/* MENU */
.menu{color:rgb(42, 81, 117);font-size: 8pt;text-align:center;position: fixed;bottom:0;left:0;width:100%;z-index:1000;padding:0 !important;background-color: rgb(231, 231, 231);border-top: 0px solid rgb(129, 129, 129)}
.menu i{font-size: 20px}
.menu div{padding:0.05rem;padding-top: 0.1rem}
.menu div div:hover{color:orange;cursor: pointer;}
.navigation-bar{font-size:12pt;height: 40px;padding: 3px;position:fixed;top:0;z-index: 1000;background-color: rgb(231, 231, 231);width:100%;color:rgb(52, 60, 82);font-weight: bold}
.menu-more{transform: translateY(100%);position: fixed;top:0;left:0;background-color: var(--bg-main);
  z-index:1000;height:100%;min-height:100vh;padding:1rem;width:100%;padding-top: 20px;}
.menu-more div div{cursor:pointer;background-color: #ececf6;color:var(--bg-blue);padding: 0.3rem;border-radius: 10px;margin-bottom: 0.2rem}
.menu-more div div:hover{background-color: #fdfdfe;color:orange }
.menu-more .title{font-size: 14pt;padding-bottom: 16px;text-transform: uppercase}
.menu-come{animation: a_menu_come 0.6s forwards}
.menu-list{height:calc(100vh - 170px);overflow-y: auto;}
.menu-apps{text-align:center}
.menu-apps a{ color:#444444;text-decoration: none;font-size: 12px;margin-right: 15px }
.menu-apps a:hover{color:rgb(58, 81, 131);text-decoration: underline}
.menu-apps img{width:30px;border-radius: 5px}
.menu-apps span{display: none;}
@media(max-width:576px){
  .menu-list::-webkit-scrollbar{display:none}
  .menu-list{height:calc(100vh - 190px);}
}
@media(min-width:577px){

  .menu-more .title{font-size: 20pt;}
  .menu-list::-webkit-scrollbar{border-radius: 10px}
  .menu-list::-webkit-scrollbar-track{background-color: rgb(153, 153, 153);border-radius: 10px;}
  .menu-list::-webkit-scrollbar-thumb{background-color:rgb(204, 204, 204);border-radius: 10px;}
}
@keyframes a_menu_come{
  from{transform:translateY(100%)}
  to{transform:translateX(0)}
}
/* AUTO COMPLETE */
.autocomplete-container{position:relative}
.autocomplete {background-color: white;color:#363636;position: absolute;z-index: 100;width:100%;}
.autocomplete li{list-style: none;display: block;padding: 4px;font-size:12pt}
.autocomplete li:hover{background-color:rgb(192, 212, 238);cursor:pointer}
.autocomplete .match{color:rgb(29, 118, 235)}
.li-hover{background-color:rgb(192, 212, 238)}
/* BORDERS  */
.borders, .borders div{border:1px solid red !important}
.borders ul{border:1px solid green !important} 
.borders li{border:1px solid yellow !important}
.label{margin-bottom: 0.1rem;margin-top: 0.4rem;}
/* FORM */
.form{display:none;padding:0.4rem;padding-bottom:60px;position: absolute;width:100%;left:0;padding-top:40px;top:0;
  background-color:var(--bg-main);;min-height:100vh;z-index:11}

.come{display: block}
.come-left{animation: a_come_left 0.6s forwards;display: block}
.come-top{animation: a_come_top 0.6s forwards;display: block}
.go{animation: a_go 0.6s forwards}
@media(min-width:577px){
  .form {transform:translateX(200%)}
  .come{animation: a_come 0.6s forwards;display: block}
}
@keyframes a_come{
  from{transform:translateX(200%)}
  to{transform:translateX(0)}
}
@keyframes a_come_left{
  from{transform:translateX(-200%)}
  to{transform:translateX(0)}
}
@keyframes a_come_top{
  from{transform:translateY(-200%)}
  to{transform:translateX(0)}
}
@keyframes a_go{
  from{transform:translateX(0)}
  to{transform:translateX(200);display: none}
}
th{cursor: pointer;}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;

}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
